<template>
  <Page full fixed>
    <ItemsBody />
  </Page>
</template>

<script>
import ItemsBody from '@/components/bodies/Items/Items.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: {
    ItemsBody
  }
}
</script>
